import React, { createFactory } from "react";
import "./row.css";
import "./Manager.css";
import { useState, useEffect } from "react";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css



import { IconButton, ButtonToolbar, Button,  Form, SelectPicker, Loader} from 'rsuite';
import DeleteIcon from '@rsuite/icons/Trash';
import ArrowLeftLineIcon from '@rsuite/icons/ArrowLeftLine';
import ArrowRightLineIcon from '@rsuite/icons/ArrowRightLine';
import PlusIcon from '@rsuite/icons/Plus';
import 'rsuite/dist/rsuite.min.css';

import axios from "axios";
import { Link } from "react-router-dom";

//DEFINITION
const IMAGES_PER_PAGE = 30; 
const MOBILE_WIDTH = 950;





//set the upload endpoint

const UPLOAD_ENDPOINT = "https://www.torten-by-lena.de/api/imageupload";
const IMAGE_DATABASE_ENDPOINT = "https://www.torten-by-lena.de";

  
function messageUserNotAuthorized() {
    confirmAlert({
        title: 'Benutzer nicht authorisiert. ',
        message: 'Bitte erneut einloggen',
        buttons: [
          {
            label: 'Schließen',
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
        willUnmount: () => {},
        afterClose: () => {},
        onClickOutside: () => {},
        onKeypress: () => {},
        onKeypressEscape: () => {},
        overlayClassName: "overlay-custom-class-name"
      });
} 

function messageError(error) {
    confirmAlert({
        title: 'Fehler ',
        message: 'Fehler aufgetreten: '+ error,
        buttons: [
          {
            label: 'Schließen',
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
        willUnmount: () => {},
        afterClose: () => {},
        onClickOutside: () => {},
        onKeypress: () => {},
        onKeypressEscape: () => {},
        overlayClassName: "overlay-custom-class-name"
      });
}

function messageUploadSuccess() {
    confirmAlert({
        title: 'Upload erfolgreich ',
        message: 'Bild erfolgreich hochgeladen ',
        buttons: [
          {
            label: 'Schließen',
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
        willUnmount: () => {},
        afterClose: () => {window.location.reload();},
        onClickOutside: () => {},
        onKeypress: () => {},
        onKeypressEscape: () => {window.location.reload();},
        overlayClassName: "overlay-custom-class-name"
      });
}


function GalleryManager() {
    const [openAddImage, setopenAddImage] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    //set is loading fla    //gallery browser
    const [imageData, setImageData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageImageData, setPageImageData] = useState(null);

    const [screenSize, setScreensize] = useState({
        //initalize the screen size with the momentary screensize
        width: window.innerWidth, 
        height: window.innerHeight,
    });
  

  
    useEffect(()=> {

        const handleResize = () => {
            setScreensize({
                width: window.innerWidth, 
                height: window.innerHeight,
            });

        };
        
        //adding event listener: every time an resize occurs the handler
        //will resize the windwow->
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize)
        };
    }, []);
    

    //load JWT from sessionStorage
    const getToken = () => {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken?.token
    };
    

    const submit = (id) => {
        confirmAlert({
            title: 'Bild löschen',
            message: 'Soll das Bild wirklich gelöscht werden?',
            buttons: [
              {
                label: 'Ja',
                onClick: () => onClickHandle(),   
              },
              {
                label: 'Nein',
              }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            willUnmount: () => {},
            afterClose: () => {window.location.reload();},
            onClickOutside: () => {},
            onKeypress: () => {},
            onKeypressEscape: () => {},
            overlayClassName: "overlay-custom-class-name"
          })

          const onClickHandle = () => {
            fetchDeleteImageFromDatabase(id);
          }
    }
    //fetch data
    useEffect(() => {
        fetchImageDataFromDatabase();
    }, [])

    //check that imageData is processed start slicing
    useEffect(()=>{
        if (!imageData != null){
            setImageDataForPage(currentPage);
        }
    }, [imageData])

    //check that slicing data is processed and set loading flag
    useEffect(()=>{
        if (pageImageData != null) {
            setIsLoading(false);
        }
    }, [pageImageData])


    useEffect(() => {
        
        fetchImageDataFromDatabase()
    }, [currentPage])






    function RenderImageList({data}) {
        return(
            <div>
                {data.map((item, index) =>(
                    <div className="image-row" key={index}>
                        <div className="image-row-left">
                            <img src={item.src} className="row-thumbnail"></img>

                            <h5>{item.title}</h5>
                        </div>
                        <div className="image-row-right">
                            <IconButton 
                                icon={<DeleteIcon />} 
                                className = "toolbar-button"
                                onClick={() =>submit(item.id)}
                            > 
                            </IconButton> 
                        </div> 



                        

                    </div>
                ))}
            </div>
        )
    }






    //fetching the images from the database -> public access no authtentification needed
    const fetchImageDataFromDatabase = async () => {
        setIsLoading(true);
        console.log("Fetching")
        fetch(IMAGE_DATABASE_ENDPOINT+`/api/imagedata?category=all`)
            .then(response => response.json())
            .then(jsonData => {
                
                const imageNumber = jsonData.length;
                //calculate the number pages based on the number of images
                setTotalPages(Math.ceil(imageNumber/IMAGES_PER_PAGE));


                //map the database data to react-gallery stlye  
                
                const formattedImageData = jsonData.map((image) => ({
                    src: IMAGE_DATABASE_ENDPOINT+"/api/images/"+image.thumbnail,
                    title: image.title,
                    id: image._id

                    // You can include other properties like captions, tags, etc. if needed
                }));
                setImageData(formattedImageData);
                return formattedImageData;

            
            })
            .catch(error => {
                console.error('Error fetching data:', error)
            
            })
            .finally(()=>{
                console.log("finished fetch")

                
            });
        
    };


    const fetchDeleteImageFromDatabase  = (id) => {
        console.log(" send del image request" + id)
        //get token from database
        //if no token notify user to relogin
        const token = getToken();
        if (!token) {
            confirmAlert({
                title: 'Anmeldung abgelaufen ',
                message: 'Bitte erneut einloggen',
                buttons: [
                  {
                    label: 'Schließen',
                  }
                ],
                closeOnEscape: true,
                closeOnClickOutside: true,
                keyCodeForClose: [8, 32],
                willUnmount: () => {},
                afterClose: () => {},
                onClickOutside: () => {},
                onKeypress: () => {},
                onKeypressEscape: () => {},
                overlayClassName: "overlay-custom-class-name"
              })
        }

        

        fetch(IMAGE_DATABASE_ENDPOINT+`/api/delete?id=${id}`,  {
            method: 'GET', // or 'POST', 'PUT', etc.
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `${token}` // Add the token here
            }})
            .then(response => {
                if (response.status == 401) {
                    confirmAlert({
                        title: 'Benutzer nicht authorisiert ',
                        message: 'Bitte erneut anmelden.',
                        buttons: [
                          {
                            label: 'Schließen',
                          }
                        ],
                        closeOnEscape: true,
                        closeOnClickOutside: true,
                        keyCodeForClose: [8, 32],
                        willUnmount: () => {},
                        afterClose: () => {},
                        onClickOutside: () => {},
                        onKeypress: () => {},
                        onKeypressEscape: () => {},
                        overlayClassName: "overlay-custom-class-name"
                      })
                } else if(response.status == 200) {
                    confirmAlert({
                        title: 'Bild gelöscht ',
                        message: 'Bild wurde erfolgreich gelöscht',
                        buttons: [
                          {
                            label: 'Schließen',
                          }
                        ],
                        closeOnEscape: true,
                        closeOnClickOutside: true,
                        keyCodeForClose: [8, 32],
                        willUnmount: () => {},
                        afterClose: () => {},
                        onClickOutside: () => {},
                        onKeypress: () => {},
                        onKeypressEscape: () => {},
                        overlayClassName: "overlay-custom-class-name"
                      })
                } else {
                    confirmAlert({
                        title: 'Fehler ',
                        message: "Fehler: " + response.status,
                        buttons: [
                          {
                            label: 'Schließen',
                          }
                        ],
                        closeOnEscape: true,
                        closeOnClickOutside: true,
                        keyCodeForClose: [8, 32],
                        willUnmount: () => {},
                        afterClose: () => {},
                        onClickOutside: () => {},
                        onKeypress: () => {},
                        onKeypressEscape: () => {},
                        overlayClassName: "overlay-custom-class-name"
                      })
                }
            
            })
            .then(jsonData => {
                return jsonData;

    })};



    const openOpenAddImage = () => {
        setopenAddImage(true);
    }

    const closeOpenAddImage = () =>{
        setopenAddImage(false);
    }

    function AddImageToGallery() {
        const [spinner, setSpinner] = useState(false);
        const [title, setTitle] = useState();
        const [category, setCategory] = useState();
        
        const [image, setImage] = useState(null);

        //error message
        const [titleError, setTitleError] = useState('');
        const [categoryError, setCategoryError] = useState('');
        const [imageError, setImageError] = useState('');

        const [screenSize, setScreensize] = useState({
            //initalize the screen size with the momentary screensize
            width: window.innerWidth, 
            height: window.innerHeight,
            });
            
    
            //chceck screen size
            useEffect(()=> {
      
              const handleResize = () => {
                  setScreensize({
                      width: window.innerWidth, 
                      height: window.innerHeight,
                  });
    
      
              };
              
              //adding event listener: every time an resize occurs the handler
              //will resize the windwow->
              window.addEventListener('resize', handleResize);
      
              return () => {
                  window.removeEventListener('resize', handleResize)
              };
          }, []);





        const selectCategory = ["Hochzeit", "Geburtstag", "Kindergeburtstag", "Taufe","Konfirmation","Sonstige"].map(item => ({
            label: item,
            value: item
        }));
        const handleImgSubmit = async (event) => {
            setSpinner(true);
            event.preventDefault();
            const formData = new FormData();
            formData.append("title", title);
            formData.append("category", category);
            formData.append("image", image);
            

            try {
                const token = getToken();
                if (token == undefined){
                    messageUserNotAuthorized();
                    setopenAddImage(false);
                    return;
                }
            }catch(error) {
                messageUserNotAuthorized();
                setopenAddImage(false);
                return;
            };

            
          

            //check that all values are specified
            if (!title) {
                setSpinner(false);
                setTitleError("Bitte Titel auswählen");
                return;
            }
            setTitleError("");


            if (!category) {
                setCategoryError("Bitte Anlass auswählen");
                setSpinner(false);
                return ; 
            }
            setCategoryError("");

            if (!image) {
                setImageError("Bitte Bild auswählen");
                setSpinner(false);
                return;
            }
            setImageError("");


            const token = getToken();
            const config = {headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `${token}`
            }}


                
            

            try{
                const data = await axios.post(UPLOAD_ENDPOINT, formData, config);
                const status = data.status;
                if (status == 401) {
                    messageUserNotAuthorized();
                    setopenAddImage();
                } else if (status == 400){
                    messageError("400 Bild nicht hochgeladen");
                } else if (status == 500){
                    messageError("500 Bild nicht hochgeladen: Datenbank Fehler");
                } else if  (status == 200){
                    messageUploadSuccess();
                }

                setopenAddImage(false);
            }
            catch(err) {
                messageError(err);

            }
            setSpinner(false);

        }; 


        

        return (
            <div className="add-image-div">
                <h3>Foto hinzufügen</h3>
                <Form fluid >
                    <Form.ControlLabel>Titel</Form.ControlLabel>
                    <Form.Control 
                        title = "title" 
                        onChange={setTitle}

                    />
                    <Form.HelpText className="error-message">{titleError}</Form.HelpText>
                    <Form.ControlLabel>Anlass: </Form.ControlLabel>
                    <Form.Control 
                        name="category" 
                        accepter={SelectPicker} 
                        data={selectCategory} 
                        onSelect={setCategory} 
                    />
                    <Form.HelpText className="error-message">{categoryError}</Form.HelpText>
                    <Form.ControlLabel>Bild auswählen</Form.ControlLabel>  
                    
                </Form>
                <input 
                        onChange={(e)=>setImage(e.target.files[0])} 
                        accept=".heic, .heif, .jpg, .jpeg"
                        name="image" 
                        type="file"
                        /> 
                <Form.HelpText className="error-message">{imageError}</Form.HelpText>
                <Form.Group>
                    <Button onClick={handleImgSubmit}>Speichern    {spinner && <Loader size="xs" style={{marginLeft: '10px'}}/>} </Button>
                    <Button onClick={closeOpenAddImage}>Abbruch</Button>
                </Form.Group>


            </div>

        )


    }

    const handlePageUp = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage+1);
        }
    }

    const handlePageDown = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    const setImageDataForPage = (page) => {
        if (imageData!=null) {
            const startIdx = (page-1)*IMAGES_PER_PAGE;
            const endIdx = startIdx + IMAGES_PER_PAGE;
            setPageImageData(imageData.slice(startIdx, endIdx));
        }
    }




    //conditional rendering of the add image form 
    return (
        <div className="manager">

                {(screenSize.width < MOBILE_WIDTH)? (   
                <div>    
                    <div className="spacer"></div>         
                    <div className="toolbar">
                            
                            
                            <ButtonToolbar>
                                <IconButton icon={<ArrowLeftLineIcon />} 
                                    appearance="default"
                                    className="toolbar-button"
                                    onClick={handlePageDown}
                                    > 
                                </IconButton> 
                                <h6>{currentPage}</h6>

                                <IconButton icon={<ArrowRightLineIcon />} 
                                    appearance="default"
                                    className="toolbar-button"
                                    onClick={handlePageUp}
                                    > 
                                </IconButton> 


                                

                                <IconButton 
                                    icon={<PlusIcon />} 
                                    className = "toolbar-button"
                                    onClick={openOpenAddImage}
                                > 
                                </IconButton> 
                            </ButtonToolbar>
                        </div>
                    </div>
                ):(
                    <div className="toolbar">
                    <ButtonToolbar>
                        <IconButton icon={<ArrowLeftLineIcon />} 
                            appearance="default"
                            className="toolbar-button"
                            onClick={handlePageDown}
                            > 
                        </IconButton> 
                        <h6>{currentPage}</h6>

                        <IconButton icon={<ArrowRightLineIcon />} 
                            appearance="default"
                            className="toolbar-button"
                            onClick={handlePageUp}
                            > 
                        </IconButton> 


                        

                        <IconButton 
                            icon={<PlusIcon />} 
                            className = "toolbar-button"
                            onClick={openOpenAddImage}
                        > 
                        </IconButton> 
                    </ButtonToolbar>
                </div>
                )}
            <div>


                {openAddImage && <AddImageToGallery/>}

            </div>
            {isLoading ? (
                <div className="gallerymanager-spinner-parent">
                    <Loader size="lg" className="gallerymanager-spinner"/>
                </div>
            ) : (
                <RenderImageList data={pageImageData} />
            )}



            

        
        

´
        </div>

    )
};



export default GalleryManager;
