import Navbar from './Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
import Login from './pages/Login';
import Impress from './pages/Impress';
import GalleryManager from './private/GalleryManager';
import PageNotFound from './pages/PageNotFound';

import { Route, Routes } from 'react-router-dom';
import { useState } from 'react';

//FOR TESTING PURPOSES ONLY

const SERVER_ADDRESS = "https://www.torten-by-lena.de"


const getToken = () => {
  const tokenString = sessionStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  return userToken?.token
};


function App() {
  const [loggedIn, setloggedIn] = useState(false);
  const token = getToken(); 
  if (token) {
    fetch(SERVER_ADDRESS+'/api/verify', {
      method: 'POST',
      headers: {
        'jwt-token': token,
      },
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.message === "success") {
          console.log(r.message);
          setloggedIn(true);
        }
      })
  }
  

  if (!loggedIn) {
    return (
      //JSX TEMPLATE which is rendered to the DOM
      <div className="App">
        <Navbar />


        <div className = "content">
          <Routes>
            <Route path = "/" element={<Home/>}/>
            <Route path = "/about" element={<About/>}/>
            <Route path = "/contact" element={<Contact/>}/>
            <Route path = "/gallery" element={<Gallery/>}/>
            <Route path = "/login" element={<Login/>}/>
            <Route path = "/impressum" element={<Impress/>}/>
            <Route path = "*" element={<PageNotFound/>}/>
          </Routes>





        </div>
      </div>
    );
  } else {
    return (
      //JSX TEMPLATE which is rendered to the DOM
      <div className="App">
        <Navbar />


        <div className = "content">
          <Routes>
            <Route path = "/" element={<Home/>}/>
            <Route path = "/about" element={<About/>}/>
            <Route path = "/contact" element={<Contact/>}/>
            <Route path = "/gallery" element={<Gallery/>}/>
            <Route path = "/login" element={<Login/>}/>
            <Route path = "/impressum" element={<Impress/>}/>
            <Route path = "/gallerymanager" element={<GalleryManager/>}/>
            <Route path = "*" element={<PageNotFound/>}/>
          </Routes>





        </div>
      </div>
    );
  }
}

//export component to other usage
export default App;
