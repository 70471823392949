import React from "react";
//import { useNavigate } from "react-router-dom";
import { useState } from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import useToken from "../useToken";

import "./Login.css"




const SERVER_ADDRESS = "https://www.torten-by-lena.de";

async function loginUser(credentials){
  await credentials;
  return fetch(SERVER_ADDRESS+"/api/auth", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}





const Login= (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const {token, setToken} = useToken(); 

    //useState for input feedback
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const handleSubmit = async e => {
      e.preventDefault();
      const token = await loginUser({
        username,
        password
      });
      setToken(token);
      if (token.message !== "success") {
        setUsernameError(token.message);
      } else {
        window.location.replace("/gallerymanager")
      }
    }

    async function updatePassword(pw){
      await setPassword(pw);
    }

    async function updateUsername (un){
      await setUsername(un);
    }
  
    const onButtonClick = () => {
        // Set initial error values to empty
        setUsernameError('')
        setPasswordError('')

        // Check if the user has entered both fields correctly
        if ('' === username) {
            setUsernameError('Bitte Username eingeben. ')
            return
        }

        if ('' === password) {
            setPasswordError('Bitte Passwort eingeben.')
            return
        }

    }
  
    return (
      <div className={'mainContainer'}>
        <div className={'titleContainer'}>
          <div>Wartung Login</div>
        </div>
        <br />
          <form onSubmit={handleSubmit}>
          <div className={'inputContainer'}>
            <input
              value={username}
              placeholder="Benutzername"
              onChange={(ev) => updateUsername(ev.target.value)}
              className={'inputBox'}
            />
            <label className="errorLabel">{usernameError}</label>
          </div>
          <br />
          <div className={'inputContainer'}>
            <input
              value={password}
              type="password"
              placeholder="Passwort"
              onChange={(ev) => updatePassword(ev.target.value)}
              className={'inputBox'}
            />
            <label className="errorLabel">{passwordError}</label>
          </div>
          <br />
          <div className={'inputContainer'}>
            <input className={'inputButton'} type="submit" onClick={onButtonClick} value={'Login'} />
          </div>
          </form>
      </div>
    )
};

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};





export default Login;
