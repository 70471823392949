import React from 'react';
import jumbotron_picture from "../assets/titelBild.jpg"
import { InstagramEmbed } from 'react-social-media-embed';
import useScreenSize from './useScreenSize';
import "./Home.css";



const  Home = () => {

     
    return (  
    <div>
            <div className="start-mobile"> 
                <section className='section-mobile'>
                    <h1 className='home-title'> Herzlich Wilkommen bei Torten-by-Lena</h1>
                </section>
                <section className='section-mobile'>
                    
                    <img src={jumbotron_picture} alt="logo" className='jumbotron-picture'/>
                </section>
                <section className='home-info-mobile'>
                    <section className='section-mobile'>
                        <h3 className='home-text' style={{fontSize: '2em', paddingBottom: '2%', textAlign: 'center'}}>Bei Lena's Tortenwerkstatt finden sie festliche Torten für jeden Anlass: </h3>
                    </section>
                    <section className='section-mobile'>
                        <h4>    </h4>
                    </section>
                    <section className='section-mobile'>
                        <h3 className='home-text' style={{fontSize: '2.5em', textAlign: 'center', fontWeight: 'bold'}}>für Hochzeiten und Geburtstage </h3>
                    </section>
                    <section className='section-mobile'>
                        <h3 className='home-text' style={{fontSize: '2.5em', textAlign: 'center', fontWeight: 'bold'}}>für Firmenfeiern oder Jubiläen</h3>
                    </section>
                    <section className='section-mobile'>
                        <h3 className='home-text' style={{fontSize: '2.5em', textAlign: 'center', fontWeight: 'bold'}}>für kleine und große Prinzessinnen</h3>
                    </section>
                    <section className='section-mobile'>
                        <h3 className='home-text' style={{fontSize: '2.5em', textAlign: 'center', fontWeight: 'bold'}}>für kleine und große Helden</h3>
                    </section>
                    <section className='section-mobile'>
                        <h4 className='home-text' style={{fontSize: '2.5em', paddingTop: '15%', textAlign: 'center'}}>Lassen Sie sich in der Galerie oder auf Instagram inspierieren.</h4>
                    </section>

                    
                    

                </section>
                <section className='home-info-mobile'>
                        <div className='instagram-embed'>
                        <InstagramEmbed url = "https://www.instagram.com/tortenbylena?igsh=MWtqZmR1c3B5d29sMQ==" width = {useScreenSize.width} />
        
        
        
                        </div>
                </section>

            </div>
    </div>
    




    );
}
 
export default Home;
