import React from "react";
import { useState, useEffect } from "react";
import { Gallery as Gall } from "react-grid-gallery";
import "./Gallery.css";

//import photoviewer
import ImageViewer from 'react-simple-image-viewer';
//import rsuite
import 'rsuite/dist/rsuite.min.css';
import { SelectPicker, IconButton } from 'rsuite';
import ArrowLeftLineIcon from '@rsuite/icons/ArrowLeftLine';
import ArrowRightLineIcon from '@rsuite/icons/ArrowRightLine';

const MOBILE_WIDTH = 950;
const IMAGES_PER_PAGE = 30;
const IMAGE_DATABASE_ENDPOINT = "https://www.torten-by-lena.de";
const FILTER_CATEGORIES = ["Hochzeit", "Geburtstag", "Kindergeburtstag", "Taufe","Konfirmation","Sonstige"].map(item => ({
    label: item,
    value: item
}));










const Gallery = () => { 

    //the page data gets generated by slicing the data from the databank
    const [showSelectPicker, setShowSelectPicker] = useState(true);
    const [imagedata, setimagedata] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [category, setCategory] = useState(null);
    const [pageImageData, setPageImageData] = useState(null);
    const [screenSize, setScreensize] = useState({
        //initalize the screen size with the momentary screensize
        width: window.innerWidth, 
        height: window.innerHeight,
    });
    const [currentImage, setCurrentImage] = useState(null);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
  
    const [isMobile, setMobile] = useState(false);
  
    useEffect(()=> {

        const handleResize = () => {
            setScreensize({
                width: window.innerWidth, 
                height: window.innerHeight,
            });
            checkMobile(window.innerWidth);

        };
        
        //adding event listener: every time an resize occurs the handler
        //will resize the windwow->
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize)
        };
    }, []);
  
      const checkMobile = (width) => {
        if (width < MOBILE_WIDTH) {
          setMobile(true);
        } else {
          setMobile(false);
        }
      };

    useEffect(() => {
  
        fetchImageDataFromDatabase(category);

    }, [category]); // Run effect only once after initial render

    useEffect(() => {
        if (imagedata != null){ 
            setImageDataForPage(currentPage);
        }
    }, [currentPage, imagedata]);




    //data fetching from database
    const fetchImageDataFromDatabase = (category = null) => {
      // Make a GET request to your Express server
      if (category == null) {
        fetch(IMAGE_DATABASE_ENDPOINT+`/api/imagedata?category=all`)
            .then(response => response.json())
            .then(jsonData => {
                setimagedata(jsonData);


                
                const imageNumber = jsonData.length;
                //calculate the number pages based on the number of images
                setTotalPages(Math.ceil(imageNumber/IMAGES_PER_PAGE));

                //map the database data to react-gallery stlye
                const formattedImageData = jsonData.map((image) => ({
                    src: IMAGE_DATABASE_ENDPOINT+"/api/images/"+image.thumbnail,
                    imageResource: IMAGE_DATABASE_ENDPOINT+"/api/images/"+image._id,
                    width: image.width,
                    height: image.height,
                    // You can include other properties like captions, tags, etc. if needed
                }));
                setimagedata(formattedImageData);
                //console.log(formattedImageData);
                

            
            
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    } else {
        fetch(IMAGE_DATABASE_ENDPOINT+`/api/imagedata?category=${category}`)
            .then(response => response.json())
            .then(jsonData => {
                setimagedata(jsonData);

                
                const imageNumber = jsonData.length;
                //calculate the number pages based on the number of images
                setTotalPages(Math.ceil(imageNumber/IMAGES_PER_PAGE));


                //map the database data to react-gallery stlye
                const formattedImageData = jsonData.map((image) => ({
                    src: IMAGE_DATABASE_ENDPOINT+"/api/images/"+image.thumbnail,
                    imageResource: IMAGE_DATABASE_ENDPOINT+"/api/images/"+image._id,
                    width: image.width,
                    height: image.height,
                    // You can include other properties like captions, tags, etc. if needed
                }));
                setimagedata(formattedImageData);
                

            
            
            })
            .catch(error => {
            console.error('Error fetching data:', error);
            });



    }
    };
    
    const setImageDataForPage = (page) => {
        const startIdx = (page-1)*IMAGES_PER_PAGE;
        const endIdx = startIdx + IMAGES_PER_PAGE;
        //console.log(imagedata.slice(startIdx, endIdx));
        setPageImageData(imagedata.slice(startIdx, endIdx));
    };


    const handlePageUp = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage+1);
            console.log("page up");
        } 
       
    }
    const handlePageDown = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage-1);
            console.log("page down");
        }
        
    }

    const openImageViewer = (index) => {
        const imageURL = pageImageData.at(index).imageResource;
        setCurrentImage(imageURL);
        setShowSelectPicker(false);
        setIsViewerOpen(true);
    };
    const closeImageViewer = () => {
        setCurrentImage(null);
        setIsViewerOpen(false);
        setShowSelectPicker(true);
      };





    return (


        <div>     
            {(screenSize.width < MOBILE_WIDTH)?(
                <div className="gallery-container-mobile">
                <div className="toolbar-top-mobile">

                    {showSelectPicker &&(
                        <SelectPicker

                            data = {FILTER_CATEGORIES}
                            onChange={setCategory}
                            searchable={false}
                            placeholder="Anlass"
                        />
                    )}


                </div>
                    <Gall 
                        images={pageImageData} 
                        enableImageSelection = {false}
                        onClick={(index) => openImageViewer(index)}
                    />
                <div className="toolbar-bottom">
                    <IconButton
                        className="toolbar-bottom-button"
                        onClick={handlePageDown}
                        icon={<ArrowLeftLineIcon />}
                    />
                    <p className="page">Seite {currentPage} </p>        
                    <IconButton
                        icon={<ArrowRightLineIcon />}
                        onClick={handlePageUp}
                        className="toolbar-bottom-button"
                    />
                </div>
                
                    
            </div>
            ): (
                <div className="gallery-container">
                <div className="toolbar-top">

                {showSelectPicker &&(
                        <SelectPicker

                            data = {FILTER_CATEGORIES}
                            onChange={setCategory}
                            searchable={false}
                            placeholder="Anlass"
                        />
                    )}

                </div>
                    <Gall 
                        images={pageImageData} 
                        enableImageSelection = {false}
                        onClick={(index) => openImageViewer(index)}
                    />
                <div className="toolbar-bottom">
                    <IconButton
                        className="toolbar-bottom-button"
                        onClick={handlePageDown}
                        icon={<ArrowLeftLineIcon />}
                    />
                    <p className="page">Seite {currentPage} </p>        
                    <IconButton
                        icon={<ArrowRightLineIcon />}
                        onClick={handlePageUp}
                        className="toolbar-bottom-button"
                    />
                </div>
                
                    
            </div>
            )}
            {isViewerOpen && (
        <ImageViewer
          backgroundStyle={{ zIndex: 9999 }}
          src={ [currentImage] }
          currentIndex={ 0 }
          disableScroll={ false }
          closeOnClickOutside={ true }
          onClose={ closeImageViewer }
        />
      )}
        </div>




    )
}

export default Gallery;
