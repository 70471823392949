import React from "react";
import { useState, useEffect } from "react";

import "./Contact.css"

const MOBILE_WIDTH = 950; 

const Contact = () => {
    const [screenSize, setScreensize] = useState({
        //initalize the screen size with the momentary screensize
        width: window.innerWidth, 
        height: window.innerHeight,
    });
  
  
    useEffect(()=> {

        const handleResize = () => {
            setScreensize({
                width: window.innerWidth, 
                height: window.innerHeight,
            });

        };
        
        //adding event listener: every time an resize occurs the handler
        //will resize the windwow->
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize)
        };
    }, []);




    return (


        <div>
            {(screenSize.width < MOBILE_WIDTH)?(
                <div className="container-parent-mobile">   
                    <div className="spacer"></div>
                    <div className="container-mobile">
                        <h3>Kontakt </h3>
                        <p>Du möchtest eine Torte bestellen oder dich weiter beraten lassen?</p>
                        <p>Kontaktiere mich per E-Mail oder telefonisch, um zu besprechen wie wir deine Tortenträume wahrmachen können.</p>
                        <div className="contact">
                            <p>Tel:    05023/9833900</p>
                            <p>Mob.:    01629342244</p>
                            <p>E-Mail: lena.braun@torten-by-lena.de</p>
                        </div> 
                    </div>

                </div>
             
            ):(
                <div className="container-parent-mobile">   
                <div className="spacer"></div>
                <div className="container-mobile">
                    <h3>Kontakt </h3>
                    <p>Du möchtest eine Torte bestellen oder dich weiter beraten lassen?</p>
                    <p>Kontaktiere mich per E-Mail oder telefonisch, um zu besprechen wie wir deine Tortenträume wahrmachen können.</p>
                    <div className="contact">
                        <p>Tel.:    05023/9833900</p>
                        <p>Mob.:    01629342244</p>
                        <p>E-Mail: lena.braun@torten-by-lena.de</p>
                    </div> 
                </div>

            </div>
            )}  









        </div>





    )
}

export default Contact; 
