import { useState, useEffect} from 'react'

const useScreenSize = () => {
    //defining with [initState, setState] = useState
    const [screenSize, setScreensize] = useState({
        //initalize the screen size with the momentary screensize
        width: window.innerWidth, 
        height: window.innerHeight,
    });

    useEffect(()=> {

        const handleResize = () => {
            setScreensize({
                width: window.innerWidth, 
                height: window.innerHeight,
            });

        };
        
        //adding event listener: every time an resize occurs the handler
        //will resize the windwow->
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize)
        };
    }, []);
    
    return screenSize;

};

export default useScreenSize;