import React from "react";
import "./About.css";
import { useState, useEffect} from 'react';

const MOBILE_WIDTH = 950;



const About = () => {
    const [screenSize, setScreensize] = useState({
        //initalize the screen size with the momentary screensize
        width: window.innerWidth, 
        height: window.innerHeight,
    });
  

  
    useEffect(()=> {

        const handleResize = () => {
            setScreensize({
                width: window.innerWidth, 
                height: window.innerHeight,
            });

        };
        
        //adding event listener: every time an resize occurs the handler
        //will resize the windwow->
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize)
        };
    }, []);



    return (
        <div>
        {(screenSize.width < MOBILE_WIDTH)? (
            <div className="container-parent-mobile">   
            <div className="spacer"></div>
            <div className="container-mobile">
                <h3>Services</h3>
                
                <p>
                    Du weißt schon genau wie deine Traumtorte aussehen soll? 
                    Dann kontaktiere mich und wir machen deinen Tortentraum wahr. 
                </p>
                <p>
                    Wenn du noch nicht weißt wie deine Traumtorte aussehen soll, dann lass
                    dich in der Bildergallerie inspierieren. Dort findest du Bilder verschiedener von
                    von mir gebackener Torten zu veschiedenen Anlässen. Gerne bin ich dir bei der Zusammenstellung
                    deiner Traumtorte behilflich. 
                </p>
                <p>
                    
                </p>


            </div>
            <div className="container-mobile"> 
                <h3>Cremefüllungen</h3>
                <p> Hier findest du klassische Cremefüllungen, welche du für deine Torte wählen 
                    kannst. Auch hier gilt alles kann, nichts muss. Du möchtest Füllungen kombinieren oder 
                    gar einen eigenen Geschmack haben? Auch hier hast du Möglichkeit nach belieben 
                    zu kombinieren und deine eigenen Ideen wahr werden zu lassen. 
            

                </p>

                <div className="taste-container">
                    <div className="taste-child">
                        <h3>Klassisch</h3>
                        <h5>Schokolade</h5>
                        <h5>Vanille</h5>
                        <h5>Karamell</h5>
                        <h5>Erdbeer</h5>
                        <h5>Nuss</h5>
                        <h5>Buttercreme</h5>
                        <h5>Nougat</h5>
                    </div>
                    <div className="taste-child">
                        <h3>Fruchtig</h3>
                        <h5>Himbeere</h5>
                        <h5>Pfirsich</h5>
                        <h5>Mandarine</h5>
                        <h5>Joghurt-Straciatella</h5>
                        <h5>Pfirsich-Mango</h5>
                        <h5>Zitrone</h5>
                    </div>
                    <div className="taste-child">
                        <h3>Extra</h3>
                        <h5>Bueno</h5>
                        <h5>Cookie</h5>
                        <h5>Raffaello</h5>
                        <h5>Giotto</h5>
                        <h5>Snickers</h5>
                    </div>
                </div>

                
            </div>

            <div className="container-mobile">  
                <h3>Lieferung</h3>
                <p>
                    Du hast eine große Veranstaltung und möchtest deine Torte geliefert haben? 
                    Für große Veranstaltungen im Umkreis von 100km liefere ich dir gerne deine Torte
                    und sorge dafür das dein Tortentraum dich sicher erreicht. 
                </p>
            </div>
            <div className="copyright">
                <h4>© Lena Braun 2024</h4>
            </div>

            </div>
        ):(
            <div>
                <div className="container-mobile">
                <h3>Services </h3>
                
                <p>
                    Du weißt schon genau wie deine Traumtorte aussehen soll? 
                    Dann kontaktiere mich und wir machen deinen Tortentraum wahr. 
                </p>
                <p>
                    Wenn du noch nicht weißt wie deine Traumtorte aussehen soll, dann lass
                    dich in der Bildergallerie inspierieren. Dort findest du Bilder verschiedener von
                    von mir gebackenen Torten zu veschiedenen Anlässen. Gerne bin ich dir bei der Zusammenstellung
                    deiner Traumtorte behilflich. 
                </p>
                <p>
                    
                </p>


            </div>
            <div className="container-mobile"> 
                <h3>Cremefüllungen</h3>
                <p> Hier findest du klassische Cremefüllungen, welche du für deine Torte wählen 
                    kannst. Auch hier gilt alles kann, nichts muss. Du möchtest Füllungen kombinieren oder 
                    gar einen eigenen Geschmack haben? Auch hier hast du Möglichkeit nach belieben 
                    zu kombinieren und deine eigenen Ideen wahr werden zu lassen. 
            

                </p>


                <div className="taste-container">
                    <div className="taste-child">
                        <h3>Klassisch</h3>
                        <h4>Schokolade</h4>
                        <h4>Vanille</h4>
                        <h4>Karamell</h4>
                        <h4>Erdbeer</h4>
                        <h4>Nuss</h4>
                        <h4>Buttercreme</h4>
                        <h4>Nougat</h4>
                    </div>
                    <div className="taste-child">
                        <h3>Fruchtig</h3>
                        <h4>Himbeere</h4>
                        <h4>Pfirsich</h4>
                        <h4>Mandarine</h4>
                        <h4>Joghurt-Straciatella</h4>
                        <h4>Pfirsich-Mango</h4>
                        <h4>Zitrone</h4>
                    </div>
                    <div className="taste-child">
                        <h3>Extra</h3>
                        <h4>Bueno</h4>
                        <h4>Cookie</h4>
                        <h4>Raffaello</h4>
                        <h4>Giotto</h4>
                        <h4>Snickers</h4>
                    </div>
                </div>

                
            </div>

            <div className="container-mobile">  
                <h3>Lieferung</h3>
                <p>
                    Du hast eine große Veranstaltung und möchtest deine Torte geliefert haben? 
                    Für große Veranstaltungen im Umkreis von 100km liefere ich dir gerne deine Torte
                    und sorge dafür das dein Tortentraum dich sicher erreicht. 
                </p>
            </div>


                   
        </div>
        )}
    </div>
    )
    
}

export default About;