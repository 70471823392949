import { Link } from 'react-router-dom'
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import "./Navbar.css";
import { slide as Menu} from 'react-burger-menu';

import logo from "./logo_lowscale.png";

const MOBILE_WIDTH = 950;

//Style for navbar 
const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--accent-color-1);
  color: #fff;
  padding: 1rem;
`;




const Navbar = () => {
    //defining with [initState, setState] = useState
    const [screenSize, setScreensize] = useState({
      //initalize the screen size with the momentary screensize
      width: window.innerWidth, 
      height: window.innerHeight,
      });

    const [isMobile, setMobile] = useState(false);

    useEffect(()=> {

        const handleResize = () => {
            setScreensize({
                width: window.innerWidth, 
                height: window.innerHeight,
            });
            checkMobile(window.innerWidth);

        };
        
        //adding event listener: every time an resize occurs the handler
        //will resize the windwow->
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize)
        };
    }, []);

    const checkMobile = (width) => {
      if (width < MOBILE_WIDTH) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    };
   

    


    return ( 
          <div>
            {(screenSize.width < MOBILE_WIDTH) ? (
              <div className="burger-menu-parent">
                    <img src={logo} alt="logo" className='logo-mobile'/>
                    <Menu disableAutoFocus>
                      <a id="Start" className="menu-item" href="/">Start</a>
                      <a id="Service" className="menu-item" href="/about">Service</a>
                      <a id="Galerie" className="menu-item" href="/gallery">Galerie</a>
                      <a id="Kontakt" className="menu-item" href="/contact">Kontakt</a>
                      <a id="Impressum" className='menu-item' href='/impressum'>Impressum</a>
                    </Menu>

              </div>
           ) : (
            <Nav>
              <ul className='navbar'>
                <img src={logo} alt="logo" className='logo'/>
                  <Link to="/" className='navbar-item'>Start</Link>
                  <Link to="/about" className='navbar-item'>Service</Link>
                  <Link to="/gallery" className='navbar-item'>Galerie</Link>
                  <Link to="/contact" className='navbar-item'>Kontakt</Link>
                  <Link to="/Impressum" className='navbar-item'>Impressum</Link>
              </ul>
            </Nav>         

           )}
          </div>



    );
}
 
export default Navbar;
