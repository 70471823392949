import React from "react";
import { Link } from 'react-router-dom'
import "./PageNotFound.css"

const PageNotFound = () => {

    return (
        <div className="page-not-found">
            <h2>Seite existiert nicht</h2>
            <p>Die von ihnen angeforderte Seite existiert nicht.</p>
            <p>Hier kommen sie zurück zur Startseite</p>
            <Link to="/"> Startseite</Link>


        </div>




    )
}

export default PageNotFound;